// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const EMPTY_VALUE = '-';

export enum SummaryStyle {
  DEFAULT = 'default',

  // The style of a summary line that has been tagged as highlighted by the
  // comparative analysis.
  HIGHLIGHTED = 'highlighted',

  // The style of a summary line that the comparative analysis failed to find
  // any references for.
  GREYED = 'greyed',
}

/**
 * Logs table sort order.
 */
export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
